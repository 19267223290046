<template>
  <div>
    <div class="header-box" v-if="!isWeiXin()">
      <mt-header :title="titleText">
        <div slot="left" @click="prev">
          <mt-button icon="back"></mt-button>
        </div>
        <!-- <mt-button icon="more" slot="right"></mt-button> -->
      </mt-header>
    </div>
    <!-- 背景 #f7f7f7 start -->
    <div class="bd-color" v-if="!white"></div>
    <!-- 背景 #f7f7f7 end -->
  </div>
</template>

<style lang="less" scoped>
.header-box{
  height: 40px;
}
</style>
<script>
import { mapActions, mapState, mapMutations } from "vuex";
import { requestImgUrl, isWeiXin, isUrlParam } from "@/utils/common";
export default {
  data() {
    this.requestImgUrl = url => requestImgUrl(url);
    this.isUrlParam = url => isUrlParam(url);
    this.isWeiXin = ()=> isWeiXin();
    return{
      titleText:'',
      white: false,
    }
  },
  props: {
    // 是否显示头部文字
    titleVisible: {
      type: Boolean,
      default: false
    },
    // 是否隐藏返回按钮
    backHide: {
      type: Boolean,
      default: true
    },
    // 是否显示搜索框
    searchshow: {
      type: Boolean,
      default: false
    },
    // 标题文字
    title: {
      type: String,
      default: ""
    },
    // 回调函数
    cb: {
      type: Function
    }
  },
  activated() {
    const v = JSON.parse(sessionStorage.getItem("orgInfo"));
    this.getTitle(v.webName);
  },
  mounted() {
    // 如果是白色背景
    if (this.$route.meta.white) {
      this.white = this.$route.meta.white;
    }
    this.getOrg();
  },
  computed: {
    ...mapState(["orgInfo"])
  },
  methods:{
    ...mapActions(["getWxConfig", "getOrgInfo", "getAd"]),
    ...mapActions('account', [
      'getVipLevel',
    ]),
    ...mapMutations([
      "orgInfoMuta",
      "loginMuta",
    ]),
    /**
     * @author: wuyifan
     * @update: 2020-12-15
     * @func
     * @description: ①获取赛事信息，页面有很多接口的数据是根据赛事id来查询；②获取机构信息，动态设置导航及一些公共文字配置
     */
    getOrg() {
      if (sessionStorage.getItem("orgInfo")) {
        const v = JSON.parse(sessionStorage.getItem("orgInfo"));
        this.orgInfoMuta(v);
        this.getTitle(v.webName);
        this.getFavicon(v.favicon);
        // 导航组件的回调函数
        if (this.cb && this.title === "") {
          this.cb();
        }
        // 登录背景图
        if (sessionStorage.getItem("loginBg")) {
          this.loginMuta(JSON.parse(sessionStorage.getItem("loginBg")));
        } else {
          // 登录背景图
          this.getAd({ positionCode: "LOGIN" }).then(res => {
            this.loginMuta(res.data);
            sessionStorage.setItem("loginBg", JSON.stringify(res.data));
          });
        }
      } else {
        this.getOrgInfo({
          queryH5AppList: true
        }).then(res => {
          const v = res.data;
          this.orgInfoMuta(v);
          sessionStorage.setItem("orgInfo", JSON.stringify(v));
          this.getTitle(v.webName);
          // 导航组件的回调函数
          if (this.cb && this.title === "") {
            this.cb();
          }
        });

        // 登录背景图
        this.getAd({ positionCode: "LOGIN" }).then(res => {
          this.loginMuta(res.data);
          sessionStorage.setItem("loginBg", JSON.stringify(res.data));
        });
      }
      this.getVip();
 
    },
    // 获取Vip信息
    getVip() {
      // VIP标识
      const VIP = sessionStorage.getItem("VIP");
      // 是否已登录标识
      const cook = this.$Cookies.get("user");
      if (!VIP && cook) {
        // 不是会员返回值为空
        this.getVipLevel().then((res)=> {
          sessionStorage.setItem("VIP", JSON.stringify(res.data));
        })
      }
    },
    /**
     * @author: wangjing
     * @update: 20191030
     * @func
     * @description: 设置标题
     * @param {string} v 机构赛事名称
     */
    getTitle(v) {
      // 标题
      let meta = this.$route.meta.title;
      const p = this.$route.fullPath;
      const h5 = this.orgInfo.h5AppList;
      for (let i = 0; i < h5.length; i++) {
        if (h5[i].targetUrl === p) {
          meta = h5[i].name;
          break;
        }
      }
      let tit = meta;
      // 标题
      if (this.$route.path == "/signname") {
        tit = meta;
      }else if (this.$route.path === "/") {
        tit = `${v}` || "首页";
      } else if (
        this.$route.path === "/account/vdetail" ||
        this.$route.path === "/account/edit" ||
        this.$route.path === "/activity/articledetails"
      ) {
        tit = this.title;
      } else if (this.title && this.title === "" && v !== "") {
        tit = `${v}`;
      } else if (this.title && v !== "") {
        tit = `${this.title}`;
        // tit = `${this.title}-${v}`;
      } else if (this.title) {
        tit = this.title;
      }

      if (this.$route.path === '/account/afterform') {
        setTimeout(()=> {
          this.titleText = this.title;
          document.title = this.title;
        },1)
      } else {
        this.titleText = tit;
        document.title = tit;
      }
      // 首页分享
      // if (this.$route.path === "/" && isWeiXin()) {
      //   this.shareHome(tit);
      // }

      // 如果页面里面已单独配置分享，则不执行该方法
      if (!meta.isShare && isWeiXin()) {
        const url = this.isUrlParam({
          getUrlParam: "referrerUserId",
        });
        const shareUrl = this.$Cookies.get("user")
          ? url + (url.indexOf('?') > 0 ? '&' : '?') +"referrerUserId=" + JSON.parse(this.$Cookies.get("user")).userId
          : url;
          // alert(shareUrl)
        this.getWxConfig({
          title: tit,
          link: shareUrl,
          desc: this.orgInfo.introduction || "",
          imgUrl: requestImgUrl(this.orgInfo.logo),
          success: function(res) {}
        });
      }
    },
    /**
     * 设置favicon
     */
    getFavicon:function(favicon){
      if(favicon){
        var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';
        link.href = requestImgUrl(favicon);
        document.getElementsByTagName('head')[0].appendChild(link);
      }
    },
    /**
     * @author: wangjing
     * @update: 20191030
     * @func
     * @description: 首页分享
     * @param {string} v 分享标题
     */
    shareHome(v) {
      this.getWxConfig({
        title: v,
        link: encodeURI(location.href.split("#")[0]),
        desc: this.orgInfo.introduction || "",
        imgUrl: requestImgUrl(this.orgInfo.logo),
        success: function(res) {}
      });
    },
    /**
     * @author: wangjing
     * @update: 20191030
     * @func
     * @description: 返回上一页
     */
    prev() {
      const q = this.$route.query;
       if (q.__token__ || q.isShare || isWeiXin()) {
        // 微信授权返回的重定向地址
        this.$router.push("/");
      } else if (q.url) {
        // 未登录之前的页面路由
        this.$router.push(decodeURIComponent(q.url));
      } else {
        this.$router.go(-1);
      }
    },
  },

}
</script>